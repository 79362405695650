import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import Carousel from 'nuka-carousel';
import useDimension from '../../../src/hooks/use-dimension';
import Tracking from '../../../src/Tracking';
import {ArrowRight, ArrowLeft} from '@styled-icons/bootstrap';
import {navigate} from 'gatsby-link';
const appConfig = require('../../../src/data.json');

function Promo({promoZone}) {
  useEffect(() => {
    Tracking.addPromotionImpression(promoZone);
  }, [promoZone]);

  return (
    <PromoWrapper
      onClick={() => {
        navigate(`/products?zone=${promoZone.id}`);
        Tracking.addPromotionImpressionClick(promoZone);
      }}>
      <Image image={`${promoZone.image}`} height={'280px'} />
      <Label>
        <div className="hover-reverse-btn">{promoZone.label}</div>
      </Label>
    </PromoWrapper>
  );
}

const PromoWrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`;

export default function PromoZoneBottomSection(props) {
  const {promoZones} = props;
  const {dimension, rwd} = useDimension();

  const iconSize = rwd === 'desktop' ? 23 : 20;

  const renderLeftIcon = ({previousSlide}) => (
    <IconContainer onClick={previousSlide} aria-label="Prev Banner">
      <ArrowLeft color="#ffffff" size={iconSize} />
    </IconContainer>
  );

  const renderRightIcon = ({nextSlide}) => (
    <IconContainer onClick={nextSlide} aria-label="Next Banner">
      <ArrowRight color="#ffffff" size={iconSize} />
    </IconContainer>
  );

  const getSlideWidth = (inWidth) => {
    if (inWidth <= appConfig.breakpoints.sm) {
      return 1;
    }
    return 2;
  };

  const slidesToShow = getSlideWidth(dimension.innerWidth);

  return (
    <Wrapper>
      <Carousel
        width={'100%'}
        height={'100%'}
        cellSpacing={20}
        getControlsContainerStyles={(key) => {
          if (key === 'CenterLeft') {
            return {
              left:
                dimension.innerWidth > appConfig.maxContentWidth + 40
                  ? -50
                  : 10,
            };
          }
          if (key === 'CenterRight') {
            return {
              right:
                dimension.innerWidth > appConfig.maxContentWidth + 40
                  ? -50
                  : 10,
            };
          }
        }}
        renderBottomCenterControls={() => false}
        renderCenterLeftControls={renderLeftIcon}
        renderCenterRightControls={renderRightIcon}
        // slideIndex={slideIndex}
        // afterSlide={slideIndex => this.setState({ slideIndex })}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToShow}>
        {promoZones
          .sort((a, b) => {
            if (a.priority === null) {
              return 1;
            } else if (b.priority === null) {
              return -1;
            }
            return a.priority - b.priority;
          })
          .map((promoZone, idx) => (
            <Promo promoZone={promoZone} key={idx} />
          ))}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const Image = styled.div`
  background-image: url(${(props) => props.image});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e0e0e0;
  height: ${(props) => props.height || '200px'};
`;

const Label = styled.div`
  width: 100%;

  & > p {
    color: #ffffff;
    margin: 0px;
  }

  & .hover-reverse-btn {
    height: 60px;
    width: 100%;

    background-color: ${appConfig.colors.third};
    border: 0;
    font-weight: bold;
    font-size: 24px;
    color: ${appConfig.colors.main};

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: ${appConfig.colors.main};
      background-color: white;
    }
  }
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: white;
  border: 0px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${appConfig.colors.main};

  :hover {
    opacity: 0.7;
  }

  :focus {
    outline: none;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    width: 30px;
    height: 30px;
  }
`;
