import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {Button} from '../../Widgets';
import {dateUtil} from '../../Utils';
import Image from '../../Components/Image';
const config = require('../../data.json');

const PORTRAIT_RADIO = 271 / 440;
const LANDSCAPE_RADIO = 120 / 180;

export default function Item({record, type = 'portrait', extraStyle = {}}) {
  const Wrapper = type === 'portrait' ? WrapperPortrait : WrapperLandscape;

  return (
    <Link style={extraStyle} to={`/article?id=${record.id}`}>
      <Wrapper>
        <Image
          src={record.image}
          alt={'article ' + record.id}
          style={{objectFit: 'contain'}}
          {...(type === 'portrait'
            ? {
                ratio: PORTRAIT_RADIO,
              }
            : {
                width: 200,
                height: 'auto',
              })}
        />

        <section>
          <div className="label">
            {(record.label && record.label[0]) || '-'}{' '}
            <span style={{marginLeft: 8}}>
              {dateUtil.format(
                dateUtil.processTimestamp(record.created),
                'YYYY-MM-DD',
              )}
            </span>
          </div>
          <h3>{record.title || 'No title'}</h3>
        </section>
      </Wrapper>
    </Link>
  );
}

const WrapperPortrait = styled.div`
  min-height: 340px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  overflow: hidden;

  & > section {
    flex: 1 0 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: ${config.colors.text};
    font-size: ${config.fontSize.sm}px;
    background-color: ${config.colors.main};

    & > .label {
      margin-bottom: 10px;
      color: ${config.colors.third};
    }

    & > h3 {
      height: 60px;
      padding: 0;
      margin: 0;
      margin-bottom: 15px;
      color: #ffffff;
      font-size: ${config.fontSize.lg}px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    margin: 0 0 10px 0;
  }
`;

const WrapperLandscape = styled.div`
  border: 1px solid #e0e0e0;
  border-top: 0;
  padding: 10px;
  display: flex;
  border-radius: 20px;
  overflow: hidden;

  & > section {
    margin-left: 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    color: ${config.colors.text};
    font-size: ${config.fontSize.sm}px;

    & > h3 {
      padding: 0;
      margin: 10px 0;
      color: #ffffff;
      font-size: ${config.fontSize.lg}px;
      flex-grow: 0;
    }
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    & > section {
      & > h3 {
        margin: 0;
        flex: 1;
      }
      & > p {
        display: none;
      }
    }
  }
`;
