import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import Tracking from '../../../src/Tracking';
const appConfig = require('../../../src/data.json');

const RATIO = 310 / 395;

function Promo({promoZone}) {
  useEffect(() => {
    Tracking.addPromotionImpression(promoZone);
  }, [promoZone]);

  return (
    <a
      target={'_blank'}
      rel="noreferrer"
      href={promoZone.link}
      onClick={() => {
        Tracking.addPromotionImpressionClick(promoZone);
      }}>
      <ImageWrapper>
        <img
          src={`${promoZone.image}`}
          alt="top promote"
          style={{width: '100%', height: 'auto'}}
          width="360"
          height="399"
        />
        <div>{promoZone.name}</div>
      </ImageWrapper>
    </a>
  );
}

export default function PromoZoneTopSection(props) {
  const {promoZones} = props;

  return (
    <Wrapper>
      <Row gutter={20}>
        {promoZones
          .sort((a, b) => {
            if (a.priority === null) {
              return 1;
            } else if (b.priority === null) {
              return -1;
            }
            return a.priority - b.priority;
          })
          .slice(0, 3)
          .map((promoZone, idx) => (
            <Col xs={24} md={8} style={{marginBottom: 15}} key={idx}>
              <Promo promoZone={promoZone} />
            </Col>
          ))}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const ImageWrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;

  > img {
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 60px;
    padding: 10px;
    background-color: ${appConfig.colors.third};
    line-height: 1.17;
    font-weight: bold;
    font-size: 24px;
    color: ${appConfig.colors.main};
  }
`;
