import React, {useContext, useCallback, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Context} from '../../../src/AppContext';
import {Row, Col} from 'antd';
import StaticImage from '../../../src/Components/StaticImage';
import {navigate} from 'gatsby';
import ProductItem from '../../../src/Components/ProductItem';
import BlogItem from '../../../src/Components/BlogItem';
import Carousel from './BannerCarousel';
import PromoZoneTopSection from './PromoZoneTopSection';
import PromoZoneBottomSection from './PromoZoneBottomSection';
import IntroSection from './IntroSection';
import AboutSection from './AboutSection';
import SocialMediaSection from './SocialMediaSection';
import {Button} from '../../../src/Widgets';
const appConfig = require('../../../src/data.json');

const slugify = require('uslug');

const intro_items = [
  {
    icon: '/images/home-diploma-icon.png',
    title: '國際雙ISO',
    subtitle: '色彩品質認證',
  },
  {
    icon: '/images/home-blackboard-icon.png',
    title: '提供多種',
    subtitle: '設計公版',
  },
  {
    icon: '/images/home-conversation-icon.png',
    title: '專人一對一',
    subtitle: '雲端諮詢服務',
  },
  {
    icon: '/images/home-social-icon.png',
    title: '企業客戶',
    subtitle: '服務方案',
  },
];

const social_icons = [
  {
    image: 'home-social-ig.jpeg',
    link: appConfig.socialMedia.instagram.link,
  },
  {
    image: 'home-social-line.jpeg',
    link: appConfig.socialMedia.line.link,
  },
  {
    image: 'home-social-fb.jpeg',
    link: appConfig.socialMedia.facebook.link,
  },
];

export default function LandingPage(props) {
  const app = useContext(Context);
  const {promoItems} = app.state;
  const [products, setProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const getProducts = useCallback(async () => {
    try {
      let resp = await app.actions.getProducts();
      setProducts(
        resp.filter((p) => app.state.productNames.indexOf(p.name) > -1),
      );
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, app.state.productNames]);

  const getBlogs = useCallback(async () => {
    try {
      let resp = await app.actions.getBlogs({
        query: {
          $and: [
            {
              label: {$not: {$regex: 'FAQ'}},
            },
            {
              label: {$not: {$regex: 'draft'}},
            },
          ],
        },
        sorting: ['-created'],
      });
      setBlogs(resp);
    } catch (ex) {
      console.warn(ex);
    }
  }, [app.actions]);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      await Promise.all([getProducts(), getBlogs()]);
      app.actions.setLoading(false);
    })();
  }, [app.actions, getProducts, getBlogs]);

  return (
    <Wrapper>
      <div className="banner-container">
        <Carousel imagesArray={promoItems.filter((x) => x.type === 'banner')} />
      </div>

      <div className="center-content">
        <div className="intro-items">
          {intro_items.map((item, idx) => (
            <ServiceItem index={idx} key={idx}>
              <div className="bg-color">
                <img src={item.icon} alt="not found" />
              </div>
              <div className="col">
                <p className="title">{item.title}</p>
                <p className="subtitle">{item.subtitle}</p>
              </div>
            </ServiceItem>
          ))}
        </div>

        <div className={'sub-promote-banner-wrapper'}>
          <PromoZoneTopSection
            promoZones={promoItems.filter((x) => x.type === 'top_zone')}
          />
        </div>

        <div className="row-section">
          <p className="title">PROMO ZONE</p>
          <p className="subtitle">活動專區</p>
          <PromoZoneBottomSection
            promoZones={promoItems.filter((x) => x.type === 'bottom_zone')}
          />
        </div>
      </div>

      <IntroSection />

      <div className="center-content">
        <div className="row-section">
          <p className="title">NEW PRODUCT</p>
          <p className="subtitle">最新商品</p>
          <Row
            gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
            style={{alignSelf: 'stretch'}}>
            {products
              .filter((p) => p.tag === 'NEW')
              .slice(0, 8)
              .map((p, idx) => {
                return (
                  <Col xs={12} md={8} lg={6} key={idx}>
                    <ProductItem
                      product={p}
                      onClick={() => {
                        navigate(`/product/${p.id}`);
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
          <Button
            className="custom-button"
            onClick={() => navigate('/products')}>
            全部商品
          </Button>
        </div>
      </div>

      <AboutSection />

      <div className="center-content">
        <div className="row-section">
          <p className="title">BEST SELLER</p>
          <p className="subtitle">熱門商品</p>
          <Row
            gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
            style={{alignSelf: 'stretch'}}>
            {products
              .filter((p) => p.tag === 'HOT')
              .slice(0, 8)
              .map((p, idx) => {
                return (
                  <Col xs={12} md={8} lg={6} key={idx}>
                    <ProductItem
                      product={p}
                      onClick={() => {
                        navigate(`/product/${p.id}`);
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
          <Button
            className="custom-button"
            onClick={() => navigate('/products')}>
            全部商品
          </Button>
        </div>
      </div>

      <div className="center-content">
        <div className="row-section">
          <p className="title">News</p>
          <p className="subtitle">最新消息</p>
          <Row
            gutter={[
              {sm: 12, md: 25, lg: 40},
              {sm: 12, md: 25, lg: 40},
            ]}
            style={{alignSelf: 'stretch'}}>
            {blogs.slice(0, 3).map((record) => (
              <Col xs={24} md={8} key={record.id}>
                <BlogItem record={record} extraStyle={{}} type="portrait" />
              </Col>
            ))}
          </Row>
          <Button
            className="custom-button"
            onClick={() => navigate('/articles')}>
            全部消息
          </Button>
        </div>
      </div>
      <SocialMediaSection social_icons={social_icons} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;

  .banner-container {
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      padding-top: 0px;
    }
  }

  .center-content {
    max-width: ${appConfig.maxContentWidth + 40}px;
    padding: 20px 20px;
    margin: 0px auto;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      padding: 0px 15px;
    }

    & > .sub-promote-banner-wrapper {
      overflow: hidden;
      margin: 60px auto;
      @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
        padding-top: 15px;
      }
    }

    & .row-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto 60px auto;

      & > .title {
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 1px;
        color: ${appConfig.colors.main};
        margin: 10px 0 0 0;
      }

      & > .subtitle {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: ${appConfig.colors.main};
        border-bottom: 0.5px solid #bdbdbd;
        padding: 0 23px 10px 23px;
        margin-bottom: 2em;
      }
    }
  }

  .intro-items {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transform: translateY(-50%);
    background-color: #ffffff;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      margin: 20px 0px 10px 0px;
      transform: translateY(0);
    }
  }

  & .col {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    & > .title {
      letter-spacing: 2px;
      color: #5a5a5a;
      margin-bottom: 5px;
    }

    & > .subtitle {
      color: #5a5a5a;
      font-size: 10px;
    }
  }

  & button.custom-button {
    border-radius: 10px;
    margin-top: 48px;

    min-width: 210px;
    background-color: ${appConfig.colors.third};
    border-color: ${appConfig.colors.third};
    font-weight: 500;
    color: ${appConfig.colors.main};

    :hover {
      background-color: white;
      color: ${appConfig.colors.main};
    }
  }
`;

const ServiceItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-basis: 25%;
  align-items: center;
  padding: 10px;

  & .bg-color {
    width: 92px;
    height: 92px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${appConfig.colors.third};
    border-radius: 50%;

    & img {
      width: 50px;
      height: 50px;
    }
  }

  & .col {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    flex-basis: 110px;

    & > .subtitle {
      color: ${appConfig.colors.main};
      font-size: 18px;
    }

    & p {
      margin-bottom: 0px;
    }

    & > .title {
      letter-spacing: 0.9px;
      color: ${appConfig.colors.main};
      margin-bottom: 5px;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 1000px) {
    & .col {
      & > .subtitle {
        font-size: 14px;
      }

      & > .title {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 860px) {
    flex-basis: 50%;

    & .bg-color {
      width: 40px;
      height: 40px;

      & img {
        width: 20px;
        height: 20px;
      }
    }

    & .col {
      & > .subtitle {
        color: ${appConfig.colors.main};
        font-size: 10px;
      }

      & > .title {
        font-size: 10px;
        line-height: normal;
        letter-spacing: normal;
      }
    }
  }

  ${(props) => props.css}
`;
