import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../src/data.json');

function IntroSection(props) {
  return (
    <Wrapper img={'../images/home-service-place.jpg'}>
      <div className="bg-image" />
      <div className="intro">
        <h3>印刷吧！PrintBar</h3>
        <p className="color-white">
          為您精心調製的專業印刷Bar！我們是50年經驗的多元印刷專家，國際ISO雙色彩認證的技術，使用最先進的進口設備在線上為您服務。急件大量歡迎詢問，並有企業客戶專人服務。提供信用卡刷卡、轉帳、超商代碼繳費、預繳加值、月結付款等多元支付方式。一起來PrintBar
          印刷吧！
        </p>
        <p className="color-pink">
          {`服務項目：\nT-shirt、帆布袋、3C包膜、IKEA包膜、燈箱、展架、立牌、DM、名片印刷、客製化壁紙、卡布燈箱、天花板光膜、輕裝潢、客製化地墊客製化陶瓷杯墊、客製化珪藻土杯墊、客製化珪藻土地墊、客製化提袋、客製化抱枕、客製化馬克杯、客製化扇子、海報輸出、大圖輸出、各式卡片印刷等服務。`}
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto 60px;

  display: flex;
  flex-direction: row;

  & > .bg-image {
    flex: 1 0 50%;

    background-image: url(${(props) => props.img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & > .intro {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: ${appConfig.colors.main};
    padding: 40px 20px;

    & > h3 {
      margin: 0px 0px 32px 0px;
      font-weight: bold;
      font-size: 56px;
      color: ${appConfig.colors.third};
    }

    & > p {
      max-width: 549px;
      width: 100%;
    }

    & > p.color-white {
      color: #ffffff;
      font-weight: 300;
      font-size: 16px;
      line-height: 1.75;
      margin-bottom: 66px;
    }

    & > p.color-pink {
      color: ${appConfig.colors.third};
      font-size: 18px;
      line-height: 2;
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;

    & > .bg-image {
      min-height: 400px;
    }

    & > .intro {
      & > h3 {
        font-size: 40px;
      }
    }
  }
`;

export default IntroSection;
