import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate} from 'gatsby';
import Carousel from 'nuka-carousel';
import useDimension from '../../../src/hooks/use-dimension';
// import {ArrowRight, ArrowLeft} from '@styled-icons/bootstrap';
import {ArrowRight} from '@styled-icons/bootstrap/ArrowRight';
import {ArrowLeft} from '@styled-icons/bootstrap/ArrowLeft';
import Image from '../../../src/Components/Image';
const appConfig = require('../../../src/data.json');

const slides = [
  {
    title:
      'PrintBar 通過國際色彩組織Idealliance的色彩G7 Master Facility ColorSpace認證',
    image: '/images/home-about-certification-01.jpg',
  },
  {
    title: 'PrintBar 通過國際色彩組織Idealliance的BrandQ ISO 19030-1認證',
    image: '/images/home-about-certification-02.jpg',
  },
];

function Slide(props) {
  const {slide} = props;
  return (
    <SlideWrapper>
      <div className="content">
        <span>{slide.title}</span>
        <Button
          type="primary"
          className="custom-button"
          onClick={() => navigate('/about')}>
          關於{appConfig.name}
        </Button>
      </div>
      <div className="gutter" />
      <div style={{alignSelf: 'stretch', flex: 1}}>
        <Image
          alt="home-introduction-certification"
          src={slide.image}
          ratio={414 / 585}
        />
      </div>
    </SlideWrapper>
  );
}

const SlideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* & > img {
    max-width: 616px;
    width: 100%;
    max-height: 426px;
    height: 100%;
  } */

  & > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* margin-right: 77px; */

    & > span {
      line-height: 1.31;
      font-weight: bold;
      font-size: 40px;
      color: ${appConfig.colors.main};
    }

    & > button.custom-button {
      margin-top: 45px;
      width: 250px;
      background-color: ${appConfig.colors.main};
      text-transform: capitalize;
      color: ${appConfig.colors.third};
    }
  }

  & > .gutter {
    flex: 0 0 40px;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column-reverse;

    & > .content {
      & > span {
        font-size: 28px;
      }
    }
  }
`;

function AboutSection(props) {
  const {dimension, rwd} = useDimension();

  const iconSize = rwd === 'desktop' ? 23 : 20;

  const renderLeftIcon = ({previousSlide}) => (
    <IconContainer onClick={previousSlide} aria-label="Prev Banner">
      <ArrowLeft color="#ffffff" size={iconSize} />
    </IconContainer>
  );

  const renderRightIcon = ({nextSlide}) => (
    <IconContainer onClick={nextSlide} aria-label="Next Banner">
      <ArrowRight color="#ffffff" size={iconSize} />
    </IconContainer>
  );

  return (
    <Wrapper>
      <div className="content-wrapper">
        <Carousel
          width={'100%'}
          height={'100%'}
          cellSpacing={20}
          getControlsContainerStyles={(key) => {
            if (key === 'CenterLeft') {
              return {
                left:
                  dimension.innerWidth > appConfig.maxContentWidth + 40
                    ? -50
                    : 10,
              };
            }
            if (key === 'CenterRight') {
              return {
                right:
                  dimension.innerWidth > appConfig.maxContentWidth + 40
                    ? -50
                    : 10,
              };
            }
          }}
          renderBottomCenterControls={() => false}
          renderCenterLeftControls={renderLeftIcon}
          renderCenterRightControls={renderRightIcon}
          // slideIndex={slideIndex}
          // afterSlide={slideIndex => this.setState({ slideIndex })}
          slidesToShow={1}
          slidesToScroll={1}>
          {slides.map((slide, idx) => (
            <Slide slide={slide} key={idx} />
          ))}
        </Carousel>
      </div>
    </Wrapper>
  );
}

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: white;
  border: 0px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${appConfig.colors.main};

  :hover {
    opacity: 0.7;
  }

  :focus {
    outline: none;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    width: 30px;
    height: 30px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: ${appConfig.colors.third};
  margin: 0 auto 60px;
  padding: 35px;

  & > .content-wrapper {
    margin: 0 auto;
    max-width: ${appConfig.maxContentWidth}px;
    width: 100%;
  }
`;

export default AboutSection;
