import React from 'react';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

function SocialMediaSection(props) {
  let {social_icons} = props;
  return (
    <Wrapper>
      <div className="social-icons-wrapper">
        {social_icons.map((icon, idx) => (
          <a
            className={'social-icon'}
            key={idx}
            href={`${icon.link}`}
            target="_blank"
            rel="noreferrer">
            <StaticImage filename={`${icon.image}`} />
          </a>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${appConfig.colors.third};
  padding: 35px;

  & > .social-icons-wrapper {
    max-width: ${appConfig.maxContentWidth}px;
    width: 100%;
    margin: 0 auto;
    display: flex;

    justify-content: center;
    flex-wrap: wrap;

    & > .social-icon {
      flex: 1 1 30%;
    }

    & > .social-icon:nth-child(2) {
      margin: 0 20px;
    }

    @media screen and (max-width: 600px) {
      & > .social-icon {
        flex: 1 0 100%;
      }
      & > .social-icon:nth-child(2) {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
`;

export default SocialMediaSection;
