import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@styled-icons/material';
import {ArrowRight, ArrowLeft} from '@styled-icons/bootstrap';
import useDimension from '../../../src/hooks/use-dimension';

const appConfig = require('../../../src/data.json');

function Indicator(props) {
  const {rwd} = useDimension();
  let {currentSlide, goToSlide, slideCount} = props;
  let indexes = Array.from(Array(slideCount).keys());

  return (
    <IconContainer
      style={{
        marginTop: 10,
        marginRight: rwd === 'desktop' ? 40 : 15,
        marginBottom: rwd === 'desktop' ? 50 : rwd === 'pad' ? 10 : 0,
        background: 'transparent',
      }}>
      <IndicatorStyle.Wrapper>
        {indexes.map((index) => (
          <IndicatorStyle.Dot
            key={index}
            selected={index === currentSlide}
            onClick={() => goToSlide(index)}
          />
        ))}
      </IndicatorStyle.Wrapper>
    </IconContainer>
  );
}

const IndicatorStyle = {
  Wrapper: styled.div`
    display: flex;
    align-items: flex-end;
  `,
  Dot: styled.div`
    cursor: pointer;
    background-color: ${(props) =>
      props.selected ? appConfig.colors.third : '#fffb'};
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin: 5px;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      margin: 4px;
    }

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      margin: 2px;
      width: 5px;
      height: 5px;
    }
  `,
};

const RATIO = 750 / 1920;

export default function BannerCarousel(props) {
  const {imagesArray} = props;

  const {rwd} = useDimension();
  const iconSize = rwd === 'desktop' ? 23 : 20;
  const iconOffset = rwd === 'desktop' ? 40 : 15;

  const renderLeftIcon = ({previousSlide}) => (
    <IconContainer
      onClick={previousSlide}
      aria-label="Prev Banner"
      style={{marginLeft: iconOffset}}>
      <ArrowLeft color="#555" style={{width: iconSize, height: iconSize}} />
    </IconContainer>
  );

  const renderRightIcon = ({nextSlide}) => (
    <IconContainer
      onClick={nextSlide}
      aria-label="Next Banner"
      style={{marginRight: iconOffset}}>
      <ArrowRight color="#555" style={{width: iconSize, height: iconSize}} />
    </IconContainer>
  );

  if (imagesArray.length === 0) {
    return (
      <ImageWrapper>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: 'grey',
          }}
        />
      </ImageWrapper>
    );
  }

  return (
    <Carousel
      width={'100%'}
      height={'100%'}
      renderBottomRightControls={(props) => <Indicator {...props} />}
      renderCenterLeftControls={renderLeftIcon}
      renderCenterRightControls={renderRightIcon}
      renderBottomCenterControls={null}
      autoplay={false}
      pauseOnHover={true}
      wrapAround={true}>
      {imagesArray.map((d, i) => (
        <ImageWrapper
          key={'carousel-image' + i}
          onClick={() => {
            if (window && d.link) {
              window.open(d.link, '_blank');
            }
          }}>
          <img src={`${d.image}`} alt="not found" />
        </ImageWrapper>
      ))}
    </Carousel>
  );
}

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: #fffb;
  border: 0px;
  width: 40px;
  height: 40px;
  border-radius: 20px;

  :hover {
    opacity: 0.5;
  }

  :focus {
    outline: none;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    width: 30px;
    height: 30px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${RATIO * 100}%;

  > img {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`;
